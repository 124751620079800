/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/js-md5@0.7.3/build/md5.min.js
 * - /npm/vue-observe-visibility@1.0.0/dist/vue-observe-visibility.umd.min.js
 * - /npm/comlink@4.3.0/dist/umd/comlink.min.js
 * - /npm/js-base64@3.7.1/base64.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
